import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from '@/utils/request'
// import httpNewsType from '@/utils/apiConfig'
import api from '@/utils/apiConfig'
import siteTypes from '@/config/nameTold'

// 悬浮图片块
import vSuspensionImg from '@/components/suspensionImg/index.vue'

import '@/assets/css/reset.css'
import '@/assets/font/font.css'

// import { detectZoom } from '@/utils/detectZoom'

import el from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// #region swiper 方案
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'
// vue2用 npm i swiper@5 安装
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper /* { default options with global component } */)
// #endregion

// element-ui框架
Vue.use(el)

// 处理笔记本系统默认系统比例为150%带来的布局影响 开启之后, 导致echarts获取坐标错误
// const m = detectZoom()
// document.body.style.zoom = (100 / Number(m)).toFixed(1)

// 挂载全局
Vue.prototype.$http = http
// Vue.prototype.$httpNewsType = httpNewsType
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$siteTypes = siteTypes

// 全局注册悬浮图片块
Vue.component('VSuspensionImg', vSuspensionImg)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
