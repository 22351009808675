import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入store
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/main'),
    name: 'main',
    redirect: { name: '网站首页' },
    meta: { title: '主页' },
    children: [
      // TODO: index忘记当时定义含义. 后续若不需要, 则删除
      {
        path: '',
        component: () => import('@/views/modules/home'),
        name: '首页',
        meta: { title: '首页', index: 0, routerId: 0 }
      },
      {
        path: 'theSolution',
        // component: () => import('@/views/modules/theSolution'),
        component: { render (c) { return c('router-view') } },
        redirect: '/theSolution',
        children: [
          {
            path: '/theSolution',
            component: () => import('@/views/modules/theSolution'),
            name: '解决方案',
            meta: { title: '解决方案', index: 10, routerId: 10 }
          },
          {
            path: 'serviceSolution',
            component: () => import('@/views/modules/theSolution/serviceSolution'),
            name: '企业招标采购综合服务解决方案',
            meta: { title: '企业招标采购综合服务解决方案', index: 10, routerId: 10 }
          },
          {
            path: 'digitalAgriculture',
            component: () => import('@/views/modules/theSolution/digitalAgriculture'),
            name: '数字农业',
            meta: { title: '数字农业', index: 10, routerId: 10 }
          },
          {
            path: 'resourceTrade',
            component: () => import('@/views/modules/theSolution/resourceTrade'),
            name: '公共资源交易',
            meta: { title: '公共资源交易', index: 10, routerId: 10 }
          }
        ]
      },
      {
        path: 'productCenter',
        // component: () => import('@/views/modules/productCenter'),
        component: { render (c) { return c('router-view') } },
        redirect: '/productCenter',
        children: [
          {
            path: '/productCenter',
            component: () => import('@/views/modules/productCenter'),
            name: '产品中心',
            meta: { title: '产品中心', index: 20, routerId: 20 }
          },
          {
            path: 'serviceSystem',
            component: () => import('@/views/modules/productCenter/serviceSystem'),
            name: '交易服务系统',
            meta: { title: '交易服务系统', index: 20, routerId: 20 }
          },
          {
            path: 'archivesSystem',
            component: () => import('@/views/modules/productCenter/archivesSystem'),
            name: '公共资源交易电子档案系统',
            meta: { title: '公共资源交易电子档案系统', index: 20, routerId: 20 }
          },
          {
            path: 'intelligentSystem',
            component: () => import('@/views/modules/productCenter/intelligentSystem'),
            name: '公共资源交易智能见证系统',
            meta: { title: '公共资源交易智能见证系统', index: 20, routerId: 20 }
          }
        ]
      },
      {
        path: 'serviceSupport',
        component: () => import('@/views/modules/serviceSupport'),
        name: '服务与支持',
        meta: { title: '服务与支持', index: 30, routerId: 30 }
      },
      {
        path: 'newsCenter',
        // component: () => import('@/views/modules/newsCenter/layout.vue'),
        component: { render (c) { return c('router-view') } },
        redirect: '/',
        meta: { title: '新闻中心', index: 40, routerId: 40 },
        children: [
          {
            path: '/',
            component: () => import('@/views/modules/newsCenter/index.vue'),
            name: '新闻中心',
            meta: { title: '新闻中心', index: 40, routerId: 40 }
          },
          {
            path: 'newsDetail',
            component: () => import('@/views/modules/newsCenter/details'),
            name: '新闻详情',
            meta: { title: '新闻详情', index: 40, routerId: 40 }
          }
        ]
      },
      {
        path: 'contactUs',
        component: () => import('@/views/modules/contactUs'),
        name: '联系我们',
        meta: { title: '联系我们', index: 50, routerId: 50 }
      },
      {
        path: 'aboutUs',
        component: () => import('@/views/modules/aboutUs'),
        name: '关于我们',
        meta: { title: '关于我们', index: 60, routerId: 60 }
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})
// 避免导航冗余
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// 路由前置守卫
router.beforeEach((to, from, next) => {
  const TITLE = '甘肃文锐电子交易网络有限公司'
  window.document.title = to.meta.title ? `${TITLE}-${to.meta.title}` : TITLE
  // 修改首页导航栏选中状态
  store.state.routerId = to.meta.routerId
  if (window.SITE_CONFIG.regionList) {
    return next()
  }
  Promise.all([Vue.prototype.$http.get('/sys/region/list', {
    params: {
      pid: 620000
    }
  })]).then(([regionRes]) => {
    regionRes = regionRes.data
    // 获取区域列表, 添加并全局变量保存
    window.SITE_CONFIG.regionList = regionRes.data
    next()
  }).catch(() => {
    next()
  })
})
// 路由拦截之后
router.afterEach(() => {
  window.scrollTo({ top: 0 })
})
export default router
