// 网站类型
const siteTypes = {
  新闻: '1628576091611717633',
  解决方案: '1630823670898561025',
  企业招标采购综合服务解决方案: '1630823811177058306',
  数字农业: '1630823874573963266',
  公共资源交易: '1630823917385224193'
}

// 轮播图
const bannerTypes = {
  首页: '1628331593560281090',
  解决方案: '1630412430762070018',
  企业招标采购综合服务解决方案: '1630480094536716289',
  数字农业: '1630480794566053890',
  公共资源交易: '1630482711631417345',
  产品中心: '1630412469211254786',
  交易服务系统: '1630486205557952514',
  公共资源交易电子档案系统: '1630486293306986498',
  公共资源交易智能见证系统: '1630486381697748994',
  服务与支持: '1630412549649616898',
  新闻中心: '1630412781355552769',
  联系我们: '1630412846233047041',
  关于我们: '1630412880471150594'
}

// 案例管理
const caseTypes = {
  首页: '1630762585143185410',
  解决方案: '1630762674641244161',
  企业招标采购综合服务解决方案: '1630762923267002370',
  数字农业: '1630762982062755841',
  公共资源交易: '1630763055047839745',
  产品中心: '1630763121636610049',
  公共资源交易电子档案系统: '1630763223075852289',
  公共资源交易智能见证系统: '1630763276670668802',
  交易服务系统: '1630763183636811778'
}

// 方案管理
const programmeTypes = {
  解决方案: '1630823670898561025',
  企业招标采购综合服务解决方案: '1630823811177058306',
  数字农业: '1630823874573963266',
  公共资源交易: '1630823917385224193'
}

export default {
  siteTypes,
  bannerTypes,
  caseTypes,
  programmeTypes
}
