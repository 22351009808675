<template>
  <transition name="el-fade-in-linear">
    <router-view />
  </transition>
</template>

<script>
import s from '@/mixins/hideScrollbarX100vw'
export default {
  mixins: [s]
}
</script>

<style lang="less">
body{
  // 左中右渐变
  background: @gbgColor;
}
// TODO: 隐藏x滚动条 待完善 媒体查询 大于1200px
@media (min-width: 1200px) {
  body{
    overflow-x: hidden;
  }
}
</style>
