import http from '@/utils/request'

// 网站类型
export function getSitetypes (params) {
  return http({
    url: '/sitetypes/findChild',
    method: 'get',
    params
  })
}

// 轮播图查询
export function getBannerInfo (params) {
  return http({
    url: '/bannerInfo/selectIspub',
    method: 'get',
    params
  })
}

// 新闻列表
export function getNewsList (params) {
  return http({
    url: '/sitenews/selectIspub',
    method: 'get',
    params
  })
}

// 新闻详情
export function getNewsDetail (params) {
  return http({
    url: '/sitenews/' + params,
    method: 'get'
  })
}

// 案例列表
export function getCaseInfo (params) {
  return http({
    url: '/sitecase/selectIspub',
    method: 'get',
    params
  })
}

// 方案列表
export function getProgrammeInfo (params) {
  return http({
    url: '/siteprogramme/selectIspub',
    method: 'get',
    params
  })
}

export default {
  getSitetypes,
  getBannerInfo,
  getNewsList,
  getNewsDetail,
  getCaseInfo,
  getProgrammeInfo
}
