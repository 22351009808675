export default {
  data () {
    return {
    }
  },
  methods: {
    getScrollbarWidth () {
      const container = document.querySelector('body')
      const inner = document.createElement('div')
      container.appendChild(inner)
      const width = window.innerWidth - inner.offsetWidth
      document.body.removeChild(inner)

      return width
    },
    setCssScrollbar () {
      const scrollbarWidth = this.getScrollbarWidth()
      // console.log('设置--scrollbar为', scrollbarWidth)
      document.documentElement.style.setProperty(
        '--scrollbar',
    `${scrollbarWidth + 1}px`
      )
    }
  },
  mounted () {
    this.setCssScrollbar()
  }
}
