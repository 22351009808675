<template>
  <div
    class="suspended-img"
    :class="'suspended-img-'+position"
    :style="imgStyle"
  >
    <img
      :src="img"
    >
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    imgStyle: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.suspended-img{
    position: absolute;
    &-left{
      left: 0;
    }
    &-right{
      right: 0;
    }
}
</style>
