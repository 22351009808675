import axios from 'axios'
import Cookies from 'js-cookie'
// import router from '@/router'
import qs from 'qs'
import isPlainObject from 'lodash/isPlainObject'

const http = axios.create({
  baseURL: window.SITE_CONFIG.apiURL,
  timeout: 1000 * 180,
  withCredentials: true
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers['Accept-Language'] = Cookies.get('language') || 'zh-CN'
  config.headers.tenantCode = Cookies.get('tenantCode') || ''
  // 默认参数
  const defaults = {}
  // 防止缓存，GET请求默认带_t参数
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ _t: new Date().getTime() }
    }
  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    }
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    }
    if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
      config.data = qs.stringify(config.data)
    }
  }
  // get序列化
  config.paramsSerializer = function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
  // console.warn(`==请求拦截==\n请求方式:${config.method}\n请求路径:${config.url}\nPOST请求数据:`, config.data, '\nGET请求数据:', config.params)
  // console.log('%c请求拦截', 'padding: 3px 20px; color: white; background: #f5ae75')
  // console.log(`%c请求方式:%c${config.method}`, 'padding: 3px; color: white; background: #298baa', 'padding: 3px; color: white; background: #823847')
  // console.log(`%c请求路径:%c${config.url}`, 'padding: 3px; color: white; background: #298baa', 'padding: 3px; color: white; background: #823847')
  // console.log('%cPOST请求数据:', 'padding: 3px; color: white; background: #298baa', config.data)
  // console.log('%cGET请求数据:', 'padding: 3px; color: white; background: #298baa', config.params)

  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data.code === 401) {
    // router.replace({ path: '/' })
    return Promise.reject(response.data.msg)
  }
  return response
}, error => {
  console.error(error)
  return Promise.reject(error)
})

export default http
